import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	QuestionCircleOutlined,
	RightCircleFilled,
	CheckOutlined,
	CopyOutlined
} from '@ant-design/icons';
import { Badge, Button, Col, Form, Popover, Row, Tooltip, Typography, message } from 'antd';
import { ContactIcon } from 'assets/img/ContactIcon';
import { CustomerInfoForm } from 'components/forms/CustomerInfoForm';
import { SelectOwner } from 'components/forms/SelectOwner';
import { MarkdownNote } from 'components/lib';
import PopoverClosableForm from 'components/popups/PopoverClosableForm';
import RdvmInfoPopover from 'components/popups/RdvmInfoPopover';
import { MouseEventHandler, useState } from 'react';
import Markdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import remarkBreaks from 'remark-breaks';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useSearchRdvmQuery } from 'services/rdvmService';
import {
	useGetProblemListByVisitIdQuery,
	useModifyDiffStatusMutation,
	useRemoveDifferentialMutation,
} from 'services/visitService';
import { BASE_QUERY_OPTIONS, FormName, MY_VET_IS_NOT_ON_THE_LIST, PET_SEX, } from 'utils/constants';
import {
	CustomerInfo,
	Differential,
	DifferentialStatuses,
	ExpandedPatient,
	PatientRecordVisit,
	ProblemList,
	RDVM,
	VisitVital,
} from 'utils/dataTypes';
import { closedErrorMsg } from 'utils/disableFuncs';
import { escapeClose } from 'utils/formFuncs';
import { formatPatientAge, formatPhoneNumber } from 'utils/formatFuncs';
import { getIntactSpeciesBreedString } from 'utils/miscFuncs';
import { PatientSection } from './PatientSection';
import { VisitSection } from './VisitSection';
import visitSidebarstyles from './visit-sidebar.module.css';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { ReasonForVisitSection } from './ReasonForVisitSection';
import { ReasonForVisitPopover } from 'components/popups/ReasonForVisitPopover';
import { useGetPatientByIdQuery } from 'services/patientService';

const { Paragraph } = Typography;
const diffListIconStyle = { fontSize: '16px', marginRight: '4px' };

interface VisitSidebarProps {
	currentVisit: PatientRecordVisit;
	vitals: VisitVital[];
	visitOnFinish: (values: any, formName: FormName) => Promise<any>;
	onComplaintSelect: (complaint_ids: number[]) => void;
	setSidebarOpen: Function;
}

export const VisitSidebar = (props: VisitSidebarProps) => {
	const { currentVisit, vitals } = props;
	const [addOwnerPopoverVisible, setAddOwnerPopoverVisible] = useState(false);

	const { urlVisitId } = useParams<{ urlVisitId: string }>();
	const visitId = parseInt(urlVisitId);
	const {addComposeBox} = useComposeBoxContext();

	const { customer_id, rdvm_id } = currentVisit;

	const { data: problemListData } = useGetProblemListByVisitIdQuery(
		visitId,
		BASE_QUERY_OPTIONS,
	);
	const { problemList, diffList } = problemListData ?? {
		problemList: [],
		diffList: [],
	};

	const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id as string, {
        ...BASE_QUERY_OPTIONS,
        skip: !currentVisit?.pet_id,
    });

	const { data: customerData } = useGetCustomerByIdQuery(
		customer_id as string,
		{ ...BASE_QUERY_OPTIONS, skip: !customer_id },
	);

	const { data: allRdvms } = useSearchRdvmQuery(visitId, BASE_QUERY_OPTIONS);

	const rdvmInfo = allRdvms?.find((rDvm) => rDvm.clinic_id === rdvm_id);

	const sortedProblemList = problemList ? [...problemList] : [];
	sortedProblemList.sort((a, b) => (a.common_name < b.common_name ? -1 : 1));

	const diffCountMap: { [key: number]: number } = {};

	problemList?.forEach((problem: ProblemList) => {
		problem?.differentials?.forEach((differential) => {
			if (!diffCountMap[differential.differential_id]) {
				diffCountMap[differential.differential_id] = 0;
			}
			diffCountMap[differential.differential_id]++;
		});
	});

	const sortedDiffList: Differential[] = diffList ? [...diffList] : [];
	sortedDiffList.sort(sortDifferentials);

	const isFinalizedVisit = Boolean(currentVisit.finalized_at);

	return (
		<div className='visit-sidebar-wrapper' style={{ position: "relative" }}>
			<Button
				type='primary'
				className='visit-sidebar-toggle-button'
				onClick={() => {
					props.setSidebarOpen((sidebarOpen: boolean) => !sidebarOpen);
				}}
				shape='circle'
				icon={<RightCircleFilled />}
			/>
			<div className='visit-sidebar-scrollable-content'>
				{patientData?.note &&
					<SidebarSection header={'Patient Note'} dataCy={'sidebarNoteSection'}>
						{patientData?.note &&
							<Markdown remarkPlugins={[remarkBreaks]} children={patientData?.note} components={{ p: 'span' }} />
						}
					</SidebarSection>
				}
				<SidebarSection
					header={'Reason'}
					dataCy='sidebarReasonForVisit'
					editClick={() => closedErrorMsg(isFinalizedVisit)}
					editClickTitle={
						<ReasonForVisitPopover
							currentVisit={currentVisit}
                            disabled={isFinalizedVisit}
                        >
							<EditOutlined
								style={{ fontSize: '16px', marginLeft: '6px', color: 'var(--gray-8)' }}
								data-pendo='sidebarReason'
							/>
						</ReasonForVisitPopover>
					}
				>
					<ReasonForVisitSection currentVisit={currentVisit} />
				</SidebarSection>
				<SidebarSection
					header={'Latest Vitals'}
				>
					<PatientSection
						currentVisit={currentVisit}
						vitals={vitals}
					/>
				</SidebarSection>

				<SidebarSection
					header='Duration and Location'
				>
					<VisitSection
						currentVisit={currentVisit}
					/>
				</SidebarSection>
				<SidebarSection
					header={'Contact'}
					editClick={() => closedErrorMsg(false)}
					editClickTitle={
						<Popover
							open={addOwnerPopoverVisible}
							overlayClassName='owner-search-popover'
							trigger='click'
							title='Change Primary Contact For Visit'
							destroyTooltipOnHide={true}
							content={
								<SelectOwner
									visit_id={visitId}
									pet_id={currentVisit.pet_id}
									setPopoverState={setAddOwnerPopoverVisible}
									onCreateNewClick={(incomingCustomerInfo: CustomerInfo) => {
										setAddOwnerPopoverVisible(false);
										addComposeBox({
											formName: FormName['owner_info'],
											content: <CustomerInfoForm customer={incomingCustomerInfo} />,
										});
									}}
								/>
							}
							placement='rightBottom'
							onOpenChange={(show) => {
									setAddOwnerPopoverVisible(show);
									escapeClose(
										addOwnerPopoverVisible,
										setAddOwnerPopoverVisible,
									);
								}
							}
						>
							{!!currentVisit.customer_id && (
								<Tooltip title='View Contact Profile'>
									<Link to={`/customer/${currentVisit.customer_id}`} data-cy="customerLink">
										<ContactIcon
                                            style={{
                                                color: 'var(--gray-8)',
                                                marginRight: 'var(--spacing-sm)',
                                                width: '17px',
                                                height: '17px',
                                            }}
                                        />
									</Link>
								</Tooltip>
							)}
							<EditOutlined
								data-cy="sidebarEdit" style={{ fontSize: '16px', marginLeft: '6px', color: 'var(--gray-8)' }}
							/>
						</Popover>
					}
				>
					<OwnerSection
						visitOnFinish={props.visitOnFinish}
						owner={customerData as CustomerInfo}
						disabled={false}
					/>
				</SidebarSection>
				<SidebarSection
					header={'rDVM'}
					editClick={() => closedErrorMsg(false)}
					editClickTitle={
						<RdvmInfoPopover
							currentVisit={currentVisit}
							currentRdvm={rdvmInfo && `${rdvmInfo?.display_name} ${rdvmInfo?.is_active ? '' : '(inactive)'}`}
							>
							<EditOutlined
								style={{ fontSize: '16px', marginLeft: '6px', color: 'var(--gray-8)' }}
							/>
						</RdvmInfoPopover>
					}
				>
					<RDVMSection
						rDVM={rdvmInfo as RDVM}
						allRdvms={allRdvms ?? []}
						visitOnFinish={props.visitOnFinish}
						disabled={false}
					/>
				</SidebarSection>
				<SidebarSection
					header={'Problems'}
					titleClick={() => {
						if (!isFinalizedVisit) {
							props.onComplaintSelect([]);
						} else {
							closedErrorMsg(isFinalizedVisit);
						}
					}}
					editClick={isFinalizedVisit
						? () => closedErrorMsg(isFinalizedVisit)
						: () => props.onComplaintSelect([])}
				>
					{sortedProblemList?.map((problem) => (
						<ProblemListItem
							key={`sidebar-problem-${problem.id}`}
							problem={problem}
							differentials={diffList}
							onComplaintSelect={() => {
								if (!isFinalizedVisit) {
									props.onComplaintSelect([]);
								} else {
									closedErrorMsg(isFinalizedVisit);
								}
							}}
						/>
					))}
				</SidebarSection>
				<SidebarSection
					header='Differentials'
					editClick={isFinalizedVisit
						? () => closedErrorMsg(isFinalizedVisit)
						: () => props.onComplaintSelect([])}
				>
					{sortedDiffList?.map((diff) => (
						<DiffListItem
							diffItem={diff}
							visit_id={visitId}
							key={`${diff.differential_id}_${diff.name}`}
							disabled={isFinalizedVisit}
						/>
					))}
				</SidebarSection>
			</div>
		</div>
	);
};

interface SidebarSectionProps {
	editClick?: any;
	editClickTitle?: string | JSX.Element;
	titleClick?: MouseEventHandler;
	className?: string;
	header?: string | JSX.Element;
	children: any;
	dataCy?: string;
}

export const SidebarSection = (props: SidebarSectionProps) => {
	let titleClassName = props.titleClick ? 'clickable title' : 'title';
	return (
		<div className={visitSidebarstyles.sidebarSection} data-cy={props?.dataCy}>
			{props.header && (
				<h3 className={visitSidebarstyles.sidebarSectionHeader}>
					<div
						className={`${titleClassName} ${visitSidebarstyles.sidebarSectionTitle}`}
						onClick={props.titleClick}
					>
						{props.header}
					</div>
					{props.editClick && (
						<div
							className='clickable-text'
							onClick={props.editClick}
							data-cy="sidebarEdit"
						>
							{props.editClickTitle ?? <EditOutlined
								style={{ fontSize: '16px', marginLeft: '6px', color: 'var(--gray-8)' }}
							/>}
						</div>
					)}
				</h3>
			)}
			<div className={visitSidebarstyles.sidebarSectionContent} style={{ marginTop: "8px" }}>
				{props.children}
			</div>
		</div>
	);
};

const SidebarProblem = (props: any) => (
	<span className='sidebar-problem'>{props.children}</span>
);

interface OwnerSectionProps {
	owner: CustomerInfo;
	visitOnFinish: (
		values: any,
		formName: FormName,
		additionalState: Object,
	) => Promise<any>;
	disabled?: boolean;
	disabledMsg?: MouseEventHandler;
}
const OwnerSection = (props: OwnerSectionProps) => {

	const { owner } = props;
	let ownerSectionContent;

	const [ownerSectionForm] = Form.useForm();
	const [ownerSectionNoteForm] = Form.useForm();

	if (
		!owner ||
		(!owner.first_name && !owner.last_name && !owner.phone_number)
	) {
		ownerSectionContent = (
			<p style={{ color: 'var(--gray-8)' }}>Click edit icon to associate an owner with this patient.
			</p>
		);
	} else {
		ownerSectionContent = (
			<>
				<PopoverClosableForm
					formName='owner_info'
					initialContent={{ ...owner }}
					placement='right'
					additionalState={{ ...owner }}
					form={ownerSectionForm}
					onFinish={(values: any) => {
						return props.visitOnFinish(values, FormName['owner_info'], {
							customerId: owner.customer_id,
						})
					}}
					formJSX={() => {
						return <CustomerInfoForm customer={owner} />;
					}}
				>
					<Row
						style={{ width: '100%' }}
						align='top'
						className='edit-sidebar-icon'
					>
						<Col>
							<Row>
								<Col>
									<span className='sidebar-owner-name'>{`${owner.first_name || ''
										} ${owner.last_name || ''}`}</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<span className='sidebar-owner-phone'>{`${formatPhoneNumber(
										owner.phone_number ?? '',
									) || ''
										}`}</span>
								</Col>
							</Row>
						</Col>
					</Row>
				</PopoverClosableForm>

				<PopoverClosableForm
					formName='owner_note'
					initialContent={{ note: owner?.note ?? '' }}
					placement='right'
					additionalState={{ ...owner }}
					form={ownerSectionNoteForm}
					formJSX={() => {
						return (
							<MarkdownNote
								fieldName={'note'}
								content={owner?.note ?? ''}
							/>
						);
					}}
				>
					<Row className='edit-sidebar-icon' data-cy="addContactNoteButton">
						<Col style={{ paddingTop: '8px' }}>
							{owner?.note ? (
								<Markdown
									remarkPlugins={[remarkBreaks]}
									children={owner?.note}
									components={{
										p: 'span',
									}}
								/>
							) : (
								<span>Add note</span>
							)}
							<EditOutlined
								style={{ fontSize: '14px', marginLeft: '6px', color: 'var(--gray-8)' }}
							/>
						</Col>
					</Row>
				</PopoverClosableForm>
			</>
		);
	}
	return <>{ownerSectionContent}</>;
};

interface RDVMSectionProps {
	rDVM: RDVM;
	allRdvms: RDVM[];
	visitOnFinish: (values: any, formName: FormName) => void;
	disabled?: boolean;
	disabledMsg?: MouseEventHandler;
}

const RDVMSection = (props: RDVMSectionProps) => {
	let rdvmContent;
	const { rDVM } = props;
	const [rDVMNoteForm] = Form.useForm();

	if (!rDVM || (!rDVM.display_name && !rDVM.phone_number)) {
		rdvmContent = (
			<span className="sidebar-instructional-text" style={{ color: 'var(--gray-8)' }}>
				Click edit icon to associate this patient with an rDVM.
			</span>
		);
	} else {
		const rdvmEmail = rDVM.display_name === 'No Primary Vet' ? null : rDVM.display_name === 'My Vet Is Not On the List' && !rDVM.override_email ? null : rDVM.override_email || rDVM.email
		rdvmContent = (
			<>
				<p style={{ color: 'var(--gray-9)', fontWeight: 500 }}>{`${rDVM.override_display_name || rDVM.display_name || ''}`}</p>
				{
					(rDVM.display_name !== MY_VET_IS_NOT_ON_THE_LIST && rDVM.display_name !== 'No Primary Vet')
					&& <p style={{ color: "var(--gray-8)" }}>{`${formatPhoneNumber(rDVM.phone_number) || ''}`}</p>
				}

				{rDVM.display_name !== 'No Primary Vet' ? (
					<>
						<Row>
							<Col style={{ paddingBottom: !rdvmEmail ? '8px' : '' }}>
								{rdvmEmail ? (
									<Paragraph style={{ color: 'var(--gray-8)', marginBottom: 0 }}
										copyable={{
											icon: [
												<CopyOutlined onClick={() => message.success('Copied!')} style={{ color: 'var(--gray-8)' }} key="copy-icon" />, 
												<CheckOutlined style={{ color: 'var(--gray-8)' }} key="copied-icon" />
											],
											tooltips: ['Copy']
											}}>
											{rdvmEmail}
									</Paragraph>
								) : (
									<span style={{ color: 'var(--veg-red)' }}>No email on file</span>
								)}
								{
									!rDVM.is_active && <Paragraph style={{ color: 'var(--veg-red)' }}>(Deactivated)</Paragraph>
								}
							</Col>
						</Row>
						<PopoverClosableForm
							formName='rdvm_note'
							initialContent={{ note: rDVM?.description ?? '' }}
							placement='bottomLeft'
							form={rDVMNoteForm}
							formJSX={() => {
								return (
									<MarkdownNote
										fieldName={'note'}
										content={rDVM?.description ?? ''}
										descriptionWarning
									/>
								);
							}}
							disabled={props.disabled}
						>
							<Row
								className='edit-sidebar-icon'
								onClick={props.disabledMsg}
								data-cy="addRdvmNoteButton"
							>
								<Col>
									{rDVM?.description ? (
										<Markdown
											remarkPlugins={[remarkBreaks]}
											children={rDVM?.description}
											components={{
												p: 'span',
											}}
										/>
									) : (
										'Add note'
									)}
									<EditOutlined
										style={{
											fontSize: '14px',
											marginLeft: '6px',
											color: 'var(--gray-8)'
										}}
									/>
								</Col>
							</Row>
						</PopoverClosableForm>
					</>
				) : (
					<Row>
						<Col>
							{rDVM?.description && (
								<Markdown
									remarkPlugins={[remarkBreaks]}
									children={rDVM?.description}
									components={{
										p: 'span',
									}}
								/>
							)}
						</Col>
					</Row>
				)}
			</>
		);
	}
	return <>{rdvmContent}</>;
};

interface ProblemListItemProps {
	differentials: Differential[];
	problem: ProblemList;
	onComplaintSelect: (complaint_ids: number[]) => void;
}
const ProblemListItem = (props: ProblemListItemProps) => {
	const { problem, onComplaintSelect } = props;
	return (
		<Row
			className='sidebar-problem'
			align='middle'
			justify='start'
			key={`problem_list_${problem.id}`}
			onClick={() => {
				onComplaintSelect([]);
			}}
		>
			<Col span={19}>
				<div>
					<SidebarProblem>{problem.common_name}</SidebarProblem>
				</div>
			</Col>
			<Col span={2}>
				{problem.chronic && (
					<Badge
						title='Chronic problem'
						count='C'
						className={`${visitSidebarstyles.badge} ${visitSidebarstyles.chronicBadge}`}
					/>
				)}
			</Col>
			<Col span={2} offset={1}>
				{problem.presenting_complaint && (
					<Badge
						title='Presenting complaint'
						count='P'
						className={`${visitSidebarstyles.badge} ${visitSidebarstyles.problemBadge}`}
					/>
				)}
			</Col>
		</Row>
	);
};

interface DiffListItemProps {
	diffItem: Differential;
	visit_id: number;
	disabled: boolean;
}
const DiffListItem = (props: DiffListItemProps) => {
	const { diffItem, visit_id } = props;
	const [modifyDiffStatus] = useModifyDiffStatusMutation();
	const [removeDiff] = useRemoveDifferentialMutation();
	const [editDiffPopoverVisible, setEditDiffPopoverVisible] = useState(false);

	const onClickDiff = () => {
		if (props.disabled) {
			closedErrorMsg(props.disabled);
		} else {
			setEditDiffPopoverVisible(true);
		}
	}

	const statusIcon =
		diffItem.status === 'suspected' || diffItem.status === null ? (
			<QuestionCircleOutlined
				style={{ ...diffListIconStyle, color: 'blue' }}
			/>
		) : diffItem.status === 'confirmed' ? (
			<CheckCircleOutlined
				style={{ ...diffListIconStyle, color: 'green' }}
			/>
		) : (
			<CloseCircleOutlined
				style={{ ...diffListIconStyle, color: 'red' }}
			/>
		);

	return (
		<>
			<Row className='sidebar-differential-item' onClick={onClickDiff}>
				<Col> {statusIcon}</Col>
				<Popover
					open={editDiffPopoverVisible && !props.disabled}
					onOpenChange={(show) => {
						if (!props.disabled) {
							setEditDiffPopoverVisible(show);
							escapeClose(
								editDiffPopoverVisible,
								setEditDiffPopoverVisible,
							);
						}
					}}
					title={
						<Row justify='space-between' align='middle'>
							<Col>Modify differential</Col>
							<Col>
								<Button
									type='ghost'
									danger
									size='small'
									icon={<DeleteOutlined />}
									onClick={() => {
										removeDiff({
											visitId: visit_id,
											id: diffItem.differential_id,
										});
									}}
								/>
							</Col>
						</Row>
					}
					trigger='click'
					content={
						<>
							<div
								style={{
									marginBottom: '8px',
									marginTop: '4px',
								}}
							>
								Change status of{' '}
								<b>
									<u>{diffItem.name}</u>
								</b>{' '}
								to:
							</div>

							<div>
								<Button
									className={
										'diff-item-confirm-button ' +
										(diffItem.status !==
											DifferentialStatuses['confirmed']
											? ''
											: 'disabled')
									}
									disabled={
										diffItem.status ===
										DifferentialStatuses['confirmed']
									}
									type='primary'
									onClick={() => {
										modifyDiffStatus({
											visitId: visit_id,
											differential_id:
												diffItem.differential_id,
											status: 'confirmed',
										});
									}}
								>
									Confirmed
								</Button>

								<Button
									disabled={
										diffItem.status ===
										DifferentialStatuses['suspected'] ||
										diffItem.status === null
									}
									type='primary'
									onClick={() => {
										modifyDiffStatus({
											visitId: visit_id,
											differential_id:
												diffItem.differential_id,
											status: 'suspected',
										});
									}}
								>
									Suspected
								</Button>

								<Button
									type='primary'
									disabled={
										diffItem.status ===
										DifferentialStatuses['ruled-out']
									}
									danger
									onClick={() => {
										modifyDiffStatus({
											visitId: visit_id,
											differential_id:
												diffItem.differential_id,
											status: 'ruled-out',
										});
									}}
								>
									Ruled-out
								</Button>
							</div>
						</>
					}
				>
					<Col className='sidebar-differential-name'>
						{`${diffItem.name} ${diffItem.count > 1 ? `(${diffItem.count})` : ''
							}`}
					</Col>
				</Popover>
			</Row>
		</>
	);
};

interface PatientSignalmentProps {
	patientData: ExpandedPatient;
}
/**
 * DO NOT WRAP INSIDE A `ROW`
 * It will set `display:flex`, which puts both these <h3> tags on the same line
 */
export const PatientSignalment = ({ patientData }: PatientSignalmentProps) => {
	return (
		<>
			<h4 className='sidebar-pet-species'>
				<span>
					{patientData?.sex
						? PET_SEX[patientData.sex]
						: ''}
				</span>
				{(patientData?.is_intact !== null || patientData?.species || patientData?.breed) &&
					' - '
				}
				{getIntactSpeciesBreedString(patientData)}
			</h4>

			<h4>
				{!!patientData?.birthday &&
					"Age: " + formatPatientAge(patientData?.birthday, patientData?.deceased_at)
				}
			</h4>
		</>
	);
};

/**
 * Sorts first by number of occurances, then alphabetical
 * @returns function that can then be passed as a callback to `.sort()`
 */
const sortDifferentials = (a: Differential, b: Differential) => {
	if (a.count > b.count) {
		return -1;
	} else if (b.count > a.count) {
		return 1;
	} else {
		return a.name < b.name ? -1 : 1;
	}
};
