import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select, Space, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useSetFocus } from 'hooks/useSetFocus';
import moment, { Moment } from 'moment';
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useGetBreedsQuery, useGetSpeciesQuery } from 'services/specieService';
import { BIRTHDAY_FORMAT, DECEASED_DATE_FORMAT, FLOAT_REGEX } from 'utils/constants';
import { PatientInfoRules } from 'utils/types/validations';
import { validateBirthDate, validateDeceasedDate } from 'utils/validationFuncs';
import { initializeForm } from '../../hooks/useOnFormInitialize';
import { PatientAlert, PatientInfo } from '../../utils/dataTypes';
import { RadioBoolean } from '../forms/fields/RadioField';
import { PatientAlerts } from './PatientAlerts';
import './css/PatientInfo.css';
import { HiddenInput } from './fields/HiddenInput';
import { roundTo } from 'utils/formatFuncs';

const { Option } = Select;

const formItemStyle: CSSProperties = {
    width: '100%',
};

interface PatientInfoRef {
    cancelDeceasedReverted: Function;
}

interface PatientInfoFormProps {
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    patientInfo: Partial<PatientInfo>;
    onPatientNameChange?: Function;
    patientAlerts: PatientAlert[];
    form: FormInstance<any>;
    cancelDeceasedReverted?: Function;
}

const PatientInfoForm: React.ForwardRefRenderFunction<PatientInfoRef, PatientInfoFormProps> = (props, patientInfoRef) => {
    const { patientInfo } = props;
    const onFormChange = props.onFormChange ?? (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));
    const [currentSpecies, setCurrentSpecies] = useState<number | null>();
    const [isDeceased, setIsDeceased] = useState<boolean | undefined | null>(!!patientInfo?.deceased_at);
    const alreadyDeceased = !!patientInfo?.deceased_at;
    const deceasedAtDate = patientInfo.deceased_at;
    const { data: species } = useGetSpeciesQuery(null);
    const { data: breeds } = useGetBreedsQuery(null);

    const [currentAgeYears, setCurrentAgeYears] = useState<number | string | undefined>(patientInfo?.ageYears);
    const [currentAgeMonths, setCurrentAgeMonths] = useState<number | string | undefined>(patientInfo?.ageMonths);

    const [isDeceasedReverted, setIsDeceasedReverted] = useState(false);

    useImperativeHandle(patientInfoRef, () => ({
        cancelDeceasedReverted: () => {
            setIsDeceasedReverted(false);
            onFormChange({
                deceased_at: patientInfo.deceased_at,
            });
        },
    }));

    useEffect(() => {
        setCurrentSpecies(species?.find((spe) => spe.name === patientInfo.species)?.id);
    }, [patientInfo, species]);

    useEffect(() => {
        initializeForm(patientInfo, onFormChange);
    }, []);

    const availableBreeds = breeds?.filter((breed) => breed.species_id === currentSpecies) || [];

    const calculateAge = (units: string, date: Moment | null) => {
        if (date) {
            const newAgeYears =
                alreadyDeceased && deceasedAtDate ? deceasedAtDate.diff(moment(date), 'years') : moment().diff(moment(date), 'years');
            if (units === 'years') {
                setCurrentAgeYears(newAgeYears);
                return newAgeYears;
            } else {
                const newAgeMonths =
                    alreadyDeceased && deceasedAtDate
                        ? deceasedAtDate.diff(moment(date), 'months') - newAgeYears * 12
                        : moment().diff(moment(date), 'months') - Number(newAgeYears) * 12;
                setCurrentAgeYears(newAgeYears);
                setCurrentAgeMonths(newAgeMonths);
                return newAgeMonths;
            }
        } else {
            return 0;
        }
    };

    const calculateBirthday = (units: string, amount: number | string) => {
        if (units === 'years') {
            setCurrentAgeYears(amount);
            if (alreadyDeceased && deceasedAtDate) {
                return moment.unix(deceasedAtDate.startOf('day').unix()).subtract(amount, 'years').subtract(currentAgeMonths, 'months');
            } else {
                return moment().subtract(amount, 'years').subtract(currentAgeMonths, 'months');
            }
        } else {
            setCurrentAgeMonths(amount);
            if (alreadyDeceased && deceasedAtDate) {
                return moment.unix(deceasedAtDate.startOf('day').unix()).subtract(currentAgeYears, 'years').subtract(amount, 'months');
            } else {
                return moment().subtract(currentAgeYears, 'years').subtract(amount, 'months');
            }
        }
    };

    const patientInfoRules: PatientInfoRules = {
        name: [
            {
                type: 'string',
                required: true,
                message: `Please ensure the patient's name has been completed.`,
            },
        ],
        species: [
            {
                type: 'string',
            },
        ],
        breed: [
            {
                type: 'string',
            },
        ],
        sex: [
            {
                type: 'string',
            },
        ],
        is_intact: [],
        birthday: [
            {
                validator: validateBirthDate('birthday', moment(patientInfo.deceased_at, 'YYYY-MM-DD')),
            },
        ],
        weight: [{ pattern: FLOAT_REGEX }],
        is_deceased: [],
        deceased_at: [
            {
                validator: validateDeceasedDate('deceased date', moment(patientInfo.birthday, 'YYYY-MM-DD')),
            },
        ],
        age: [
            {
                type: 'number',
            },
        ],
    };

    const handleSpeciesChange = (_: any, option: any) => {
        if (option) {
            onFormChange({
                species: option.label,
                breed: null,
            });
            setCurrentSpecies(option.value);
        } else {
            onFormChange({
                species: null,
                breed: null,
            });
            setCurrentSpecies(null);
        }
    };

    const handleBreedsChange = (_: any, option: any) => {
        if (option) {
            onFormChange({
                breed: option.label,
            });
        } else {
            onFormChange({
                breed: null,
            });
        }
    };

    const ref = useRef<any>(null);

    useSetFocus(ref, true);

    const weightInput = <Input type='number' suffix='kg' />;

    const weightValueElement = (
        <span>
            <strong>{roundTo(patientInfo.latest_weight, 3)} kg</strong>
        </span>
    );

    const weightElement = patientInfo.latest_weight ? weightValueElement : weightInput;

    return (
        <>
            <Form.Item
                preserve={false}
                name='name'
                label='Patient’s Name'
                data-cy='newPatientName'
                style={formItemStyle}
                labelCol={{ span: 7 }} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={{ span: 17 }} //Width of INPUT, in "columns" (like <Col span={12})
                rules={patientInfoRules.name}
            >
                <Input
                    ref={ref}
                    autoFocus
                    onChange={(e) => {
                        onFormChange({
                            name: e.target.value,
                        });
                        if (props.onPatientNameChange) {
                            props.onPatientNameChange(e.target.value);
                        }
                    }}
                />
            </Form.Item>
            <Form.Item
                preserve={false}
                name='species'
                label='Species'
                data-cy='newPatientSpecies'
                style={formItemStyle}
                labelCol={{ span: 7 }} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={{ span: 17 }} //Width of INPUT, in "columns" (like <Col span={12})
                rules={patientInfoRules.species}
            >
                <Select<string>
                    allowClear
                    showSearch
                    style={formItemStyle}
                    options={species?.map((specie) => ({
                        value: specie.id,
                        label: specie.name,
                    }))}
                    filterOption={(inputValue, option) => (option?.label as string)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    onChange={handleSpeciesChange}
                    placeholder='Select a Species'
                />
            </Form.Item>
            <Form.Item
                preserve={false}
                name='breed'
                label='Breed'
                data-cy='newPatientBreed'
                style={formItemStyle}
                labelCol={{ span: 7 }} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={{ span: 17 }} //Width of INPUT, in "columns" (like <Col span={12})
                rules={patientInfoRules.breed}
                dependencies={['species']}
            >
                <Select<string>
                    allowClear
                    showSearch
                    style={formItemStyle}
                    options={availableBreeds.map((breed) => ({
                        value: breed.id,
                        label: breed.name,
                    }))}
                    filterOption={(inputValue, option) => (option?.label as string)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    onChange={handleBreedsChange}
                    placeholder='Select a Breed'
                    disabled={!(availableBreeds.length > 0)}
                />
            </Form.Item>
            <Form.Item
                preserve={false}
                name='sex'
                label='Sex'
                data-cy='newPatientSex'
                style={formItemStyle}
                labelCol={{ span: 7 }} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={{ span: 17 }} //Width of INPUT, in "columns" (like <Col span={12})
                rules={patientInfoRules.sex}
            >
                <Select
                    onSelect={(value) =>
                        onFormChange({
                            sex: value,
                        })
                    }
                >
                    <Option value={'M'} key={`sex_m`}>
                        Male
                    </Option>
                    <Option value={'F'} key={`sex_f`}>
                        Female
                    </Option>
                </Select>
            </Form.Item>
            <RadioBoolean
                onFormChange={(value: boolean | null) => {
                    onFormChange({
                        is_intact: value,
                    });
                }}
                name='is_intact'
                label='Spayed/Neutered'
                dataCy='newPatientSpayed'
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                formItemStyle={formItemStyle}
                includeUnknown={true}
                invert={true}
                rules={patientInfoRules.is_intact}
            ></RadioBoolean>
            <Space style={{ display: 'flex' }} align='baseline'>
                <Form.Item
                    preserve={false}
                    name='birthday'
                    label='Birthday'
                    data-cy='newPatientBirthday'
                    style={formItemStyle}
                    rules={patientInfoRules.birthday}
                    labelCol={{ span: 17 }} //Width of LABEL, in "columns" (like <Col span={4})
                    wrapperCol={{ span: 7 }} //Width of INPUT, in "columns" (like <Col span={12})
                >
                    <DatePicker
                        showTime={false}
                        format={BIRTHDAY_FORMAT}
                        onChange={(e) =>
                            onFormChange({
                                ageYears: calculateAge('years', e),
                                ageMonths: calculateAge('months', e),
                            })
                        }
                        style={{ width: 130 }}
                    />
                </Form.Item>
                <Form.Item
                    className='visible-spinner'
                    preserve={false}
                    name='ageYears'
                    label='Age'
                    style={{ marginLeft: 75 }}
                    rules={patientInfoRules.age}
                    labelCol={{ span: 9 }} //Width of LABEL, in "columns" (like <Col span={4})
                    wrapperCol={{ span: 15 }} //Width of INPUT, in "columns" (like <Col span={12})
                >
                    <InputNumber
                        min={0}
                        style={{ width: 55, marginLeft: 3 }}
                        data-cy='newPatientAgeYears'
                        onChange={(e) =>
                            onFormChange({
                                birthday: calculateBirthday('years', e ?? 0),
                            })
                        }
                        //placeholder="Years"
                    />
                </Form.Item>
                <label>Y</label>
                <Form.Item
                    className='visible-spinner'
                    preserve={false}
                    name='ageMonths'
                    style={formItemStyle}
                    rules={patientInfoRules.age}
                    labelCol={{ span: 9 }} //Width of LABEL, in "columns" (like <Col span={4})
                    wrapperCol={{ span: 15 }} //Width of INPUT, in "columns" (like <Col span={12})
                >
                    <InputNumber
                        max={11}
                        min={0}
                        style={{ width: 55 }}
                        data-cy='newPatientAgeMonths'
                        onChange={(e) =>
                            onFormChange({
                                birthday: calculateBirthday('months', e ?? 0),
                            })
                        }
                        //placeholder="Months"
                    />
                </Form.Item>
                <label>M</label>
            </Space>
            <Form.Item
                data-cy='patientInfoWeight'
                name='initial_weight'
                rules={patientInfoRules.weight}
                label='Weight'
                style={formItemStyle}
                labelCol={{ span: 7 }} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={{ span: 17 }} //Width of INPUT, in "columns" (like <Col span={12})
            >
                {weightElement}
            </Form.Item>
            {patientInfo.pet_id && (!alreadyDeceased || isDeceasedReverted) && (
                <RadioBoolean
                    onFormChange={(value: boolean | null) => {
                        onFormChange({
                            is_deceased: value,
                            deceased_at: patientInfo.deceased_at,
                        });
                        setIsDeceased(value);
                    }}
                    name='is_deceased'
                    label='Deceased'
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 17 }}
                    formItemStyle={formItemStyle}
                    includeUnknown={false}
                    invert={false}
                    rules={patientInfoRules.is_deceased}
                    dataCy='patientDeceased'
                ></RadioBoolean>
            )}
            {alreadyDeceased && !isDeceasedReverted ? (
                <>
                    <Row style={{ width: '100%', marginTop: '10px' }}>
                        <Col span={7}>Deceased Date:</Col>
                        <Col span={17} offset={0}>
                            {patientInfo?.deceased_at && patientInfo.deceased_at.format('YYYY-MM-DD')}
                            <Button
                                className='delete-deceased-button'
                                type='ghost'
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    onFormChange({
                                        deceased_at: null,
                                        is_deceased: false,
                                    });
                                    setIsDeceasedReverted(true);
                                    setIsDeceased(false);
                                }}
                            />
                        </Col>
                    </Row>

                    <Form.Item preserve={false} name='deceased_at' hidden>
                        <DatePicker style={formItemStyle} showTime={false} format={DECEASED_DATE_FORMAT} />
                    </Form.Item>
                </>
            ) : isDeceased ? (
                <Form.Item
                    preserve={false}
                    name='deceased_at'
                    label='Deceased Date'
                    data-cy='patientDeceasedDate'
                    style={formItemStyle}
                    labelCol={{ span: 7 }} //Width of LABEL, in "columns" (like <Col span={4})
                    wrapperCol={{ span: 17 }} //Width of INPUT, in "columns" (like <Col span={12})
                    rules={patientInfoRules.deceased_at}
                >
                    <DatePicker style={formItemStyle} showTime={false} format={DECEASED_DATE_FORMAT} />
                </Form.Item>
            ) : null}
            {isDeceasedReverted && <HiddenInput fieldName='is_deceased_reverted' initialValue={true} />}
            {!patientInfo.name && (
                <Form.Item
                    name='reason_for_visit'
                    label='Reason for Visit'
                    data-cy='reasonForVisit'
                    style={formItemStyle}
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 17 }}
                >
                    <Input />
                </Form.Item>
            )}
            <Form.Item
                name='note'
                label={
                    <Tooltip title='Notes added here will appear on all visits associated with this patient. Use “Alerts” for short prominent warnings.'>
                        Notes <QuestionCircleOutlined style={{ margin: '0 4px' }} />
                    </Tooltip>
                }
                style={formItemStyle}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                data-cy='newPatientNote'
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={'pet_id'}
                hidden
                style={formItemStyle}
                labelCol={{ span: 7 }} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={{ span: 17 }} //Width of INPUT, in "columns" (like <Col span={12})
            >
                <TextArea></TextArea>
            </Form.Item>
            <PatientAlerts initialAlerts={props.patientAlerts} form={props.form} />
        </>
    );
};

export default forwardRef(PatientInfoForm);
