import { CalendarOutlined } from '@ant-design/icons';
import { Avatar, Button, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { HOSPITALIZATION_LEVELS, PatientRecordVisit } from 'utils/dataTypes';
import { formatCurrency } from 'utils/formatFuncs';
import { DASHBOARD_STATUS_MAP } from 'utils/stringFormatting';
import { Separator } from '.';

interface VisitsAndNotesCardProps {
    visit: PatientRecordVisit;
}

const VisitCard: React.FC<VisitsAndNotesCardProps> = ({ visit }) => {
    const history = useHistory();

    const getVisitStatus = (status: string, hospitalization_level?: HOSPITALIZATION_LEVELS) => {
        return DASHBOARD_STATUS_MAP[status] === 'Hospitalized'
            ? `${DASHBOARD_STATUS_MAP[status]} - ${hospitalization_level}`
            : (visit.finalized_at && DASHBOARD_STATUS_MAP[status] !== 'No show')
            ? "Closed"
            : DASHBOARD_STATUS_MAP[status];
    };

    return (
        <div className='visits-and-note-card' data-cy="visitCard">
            <div className='visits-and-note-card__header'>
                <span>
                    <Avatar style={{ backgroundColor: 'var(--veg-secondary-teal)' }} icon={<CalendarOutlined />} />
                </span>
                <span>
                    <div className='visits-and-note-card__title'>Visit</div>
                    <div>
                        <span>{moment.unix(visit.arrived_at).format('MM/DD/YYYY hh:mm a')}</span>

                        <Separator />
                        <span
                            style={{
                                color: visit.balance_due === 0 ? 'inherit' : visit.balance_due < 0 ? 'var(--veg-green)' : 'var(--veg-red)',
                                fontWeight: 600,
                            }}
                            data-cy="amountDue"
                        >
                            {formatCurrency(Math.abs(visit.balance_due))} {visit.balance_due < 0 ? 'CR' : ''}
                        </span>

                        <ReasonForVisit reasonForVisit={visit.reason_for_visit} />

                        <CustomerInfo customer_id={visit.customer_id} />

                        <Separator />
                        <span>{getVisitStatus(visit.status, visit.hospitalization_level)}</span>
                    </div>
                </span>
                <span className='visits-and-note-card__function-button'>
                    <Button
                        onClick={() => {
                            history.push(`/visit/${visit.id}`);
                        }}
                        data-cy="goToVisitButton"
                    >
                        View
                    </Button>
                </span>
            </div>
        </div>
    );
};

const ReasonForVisit = ({reasonForVisit} : {reasonForVisit: string | null}) => {
    if (!reasonForVisit) return null;

    return (
        <Tooltip
            title={reasonForVisit.length > 50 ? reasonForVisit : null}
        >
            <span className='visits-and-note-card__reason-for-visit' data-cy="visitCardReasonForVisit">
                <span>
                    <Separator />
                    {reasonForVisit}
                </span>
            </span>
        </Tooltip>
    );
};

const CustomerInfo = ({ customer_id }: { customer_id: string | null }) => {
    const { data: customerData } = useGetCustomerByIdQuery(customer_id ?? '', { skip: !customer_id });

    if (!customer_id) return null;

    return (
        <span>
            <Separator />
            {customerData?.first_name} {customerData?.last_name}
        </span>
    );
};

export default VisitCard;
